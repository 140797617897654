/*
  ❗ This file contains styles that apply globally to the whole application.
  ❗ Put some CSS in this file only in case of urgent need.
  ❗ Whenever possible, create local styles for your React components (CSS modules, CSS-in-JS).
  ❗ The only excuse to put CSS in this file is when you want to style a third-party library that doesn't allow customization using its API.
  ❗ If you want to apply a global style only when a certain component is mounted, use the `@global` directive of the JSS library (https://material-ui.com/customization/globals/#global-css).
*/

/*
  Removes autofill styles in all inputs (for example, login and password).
  https://github.com/mui/material-ui/issues/283#issuecomment-378051636
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
  background: transparent !important;
  color: inherit !important;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Safari adds -webkit-text-fill-color that overrides color property when buttons are used inside fieldset element */
button {
  -webkit-text-fill-color: initial;
}

html,
body,
#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar:vertical {
  border-left: 1px solid #EDEEF2;
}

::-webkit-scrollbar:horizontal {
  border-top: 1px solid #EDEEF2;
}

::-webkit-scrollbar-track {
  border: 5px solid transparent;
  background-color: #F6F7F8;
  border-radius: 16px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 16px;
  background-color: #CACDD7;
  background-clip: content-box;
}

.leadinModal {
  display: none !important;
  visibility: hidden !important;
  overflow: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
