@keyframes slide-notification-item {
  to {
    transform: translateX(-110%);
  }
}

@keyframes collapse-notification-item {
  from {
    transform: translateX(-110%);
  }

  to {
    max-height: 0;
    margin: 0;
    padding: 0;
  }
}


.notification-item-exit-active {
  animation:
    slide-notification-item 300ms forwards ease-in-out 100ms,
    collapse-notification-item 300ms forwards ease-in-out 400ms;
}
