.email-body {
  width: 100%;
  display: flex;
  flex-direction: column-reverse; /* place toolbar under editor */
}

.email-body .ql-container {
  min-height: 150px;
  max-height: 500px;
}

.email-body .ql-toolbar {
  box-shadow: none;
  border: 0;
  padding: 16px 0 0;
}

.email-body .ql-snow.ql-toolbar button {
  width: 24px;
  padding: 3px;
}

.email-body .ql-toolbar.ql-snow .ql-formats {
  margin: 0;
}

.email-body .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  top: auto;
  bottom: 100%;
  margin: 0;
}

.email-body .ql-toolbar.ql-snow + .ql-container.ql-snow  {
  border-top: 1px solid #EDEEF2;
  border-radius: 2px;
}

.email-body--hidden-toolbar .ql-toolbar {
  display: none;
}

.ql-email-height .ql-editor{
  max-height: 40vh;
  overflow: auto;
}

.email-body--no-max-height .ql-editor {
  max-height: 100%;
}

.email-body--no-border .ql-container {
  border: none;
  box-shadow: none;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 10px 0;
}
