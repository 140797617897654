.ql-container {
  font-family: 'Inter';
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-color: #EDEEF2;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ql-container.ql-snow {
  border-color: #EDEEF2;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
}

.ql-container.ql-disabled {
  border: none;
  box-shadow: none;
}

.ql-container.ql-disabled .ql-editor {
  padding: 0;
}

.error .ql-toolbar,
.error .ql-container {
  border-color: #C70008;
}
