@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-America';
  src: url('assets/fonts/GT-America-Standard-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-America';
  src: url('assets/fonts/GT-America-Standard-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'GT-America';
  src: url('assets/fonts/GT-America-Standard-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Black.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-BlackItalic.ttf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-BoldItalic.ttf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Book.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-BookItalic.ttf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-ExtraBoldItalic.ttf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-MediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-RegularItalic.ttf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('assets/fonts/biotif/Biotif-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;  /* Typically Black is 900 */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;  /* Bold */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;  /* Extra Bold */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;  /* Extra Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;  /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;  /* Medium */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;  /* Regular */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;  /* SemiBold */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;  /* Thin */
  font-style: normal;
  font-display: swap;
}

