@keyframes Toastify__trackProgress--vertical {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0);
  }
}

@keyframes open-feed-notification {
  0% {
    opacity: 0.5;
    transform: scale(0.3);
  }

  70% {
    transform: scale(1);
  }

  85% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.notification-toast-enter-active {
  animation-name: open-feed-notification;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  transform-origin: top right;
}

@keyframes close-feed-notification {
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

.notification-toast-exit-active {
  animation-name: close-feed-notification;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.7, -0.4, 0.4, 1.4);
  transform-origin: top right;
}
