.cropper-view-box {
  outline: 1px dashed rgba(255, 255, 255, 0.5);
}

/* Additional shadow circle */
.cropper-view-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(closest-side, transparent 100%, rgba(0, 0, 0, 0.5));
  background-size: 100% 100%;
  background-position: 50% 50%;
}

.cropper-line {
  background: none;
}

.cropper-point {
  background: #fff;
  opacity: 1;
}

.cropper-point.point-se {
  width: 5px;
  height: 5px;
}
