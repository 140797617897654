.rich-text-editor .ql-container {
  font-family: 'inherit';
}

.ql-editor p, .ql-editor ul{
  font-size: 16px;
}

@media (min-width: 1920px) {
  .ql-editor p, .ql-editor ul {
    font-size: 18px;
  }
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 10px 0;
}

.rich-text-editor .ql-toolbar {
  box-shadow: none;
  border-color: #D5D5D5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rich-text-editor .ql-container {
  box-shadow: none;
  border-color: #D5D5D5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-right-radius: 4px;
}

.rich-text-editor .ql-toolbar.ql-snow .ql-formats {
  margin-right: 2px;
}

.rich-text-editor .ql-toolbar.ql-snow .ql-formats .ql-stroke {
  stroke-width: 1.3;
}


